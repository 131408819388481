<template>
  <div>
    <Header :title="title" theme="light" />
    <!-- <img class="logo" src="../../assets/images/login/ic_log_in_logo.webp" /> -->
    <div class="register">
      <div class="inputArea">
        <login-input
          type="text"
          icon="ic_runningpoints_mine.webp"
          placeholder="请输入正确的手机号"
          v-model="userMobile"
        />
        <div class="flex-sb-c">
          <login-input
            type="password"
            icon="ic_runningpoints_verificationcode.png"
            placeholder="请输入验证码"
            v-model="verificationCode"
          />
          <button class="innerBtn" @click="getCode()">获取验证码</button>
        </div>

        <login-input
          type="password"
          icon="ic_runningpoints_password.webp"
          placeholder="请输入新密码"
          v-model="password"
        />
        <login-input
          type="password"
          icon="ic_runningpoints_password.webp"
          placeholder="请再次输入新密码"
          v-model="checkPassword"
        />
      </div>
      <button class="btn oval mb_1rem" @click="goRegister()">确定</button>
    </div>
  </div>
</template>

<script>
import LoginInput from "@/components/LoginInput";
import Header from "@/components/Header";
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
export default {
  name: "register",
  components: {
    LoginInput,
    Header,
  },
  data() {
    return {
      userMobile: "",
      password: "",
      checkPassword: "",
      verificationCode: "",
    };
  },
  computed: {
    ...mapState("sms", ["smsPasswordCodeData"]),

    title() {
      return this.$route.meta.name;
    },
  },
  watch: {
    smsPasswordCodeData(e) {
      if (e.code == "ok") {
        this.$router.go(-1);
      }
    },
  },
  methods: {
    ...mapActions("sms", ["getSmsSecurityCode", "putSmsSecurityCode"]),
    back() {
      this.$router.go(-1);
    },
    goRegister() {
      if (this.userMobile.length != 11) {
        Message({
          message: "请输入11位手机号",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (this.verificationCode.length != 6) {
        Message({
          message: "短信验证码为6位数的数字",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (this.checkPassword != this.password) {
        Message({
          message: "再次输入密码错误",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else {
        let data = {
          userMobile: this.userMobile,
          newPassword: this.password,
          verifyCode: this.verificationCode,
          confirmNewPassword: this.checkPassword,
        };
        this.putSmsSecurityCode(data);
      }
    },
    getCode() {
      let data = {
        userMobile: this.userMobile,
      };
      this.getSmsSecurityCode(data);
    },
    callMessage() {},
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.register {
  padding: 0 1.2em 1.2rem 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
    @media (min-width:480px){
    padding: 1.2em 7.2em 1.2rem 7.2em;
  }
  .back {
    width: 100%;
    flex: 8em 0 0;
    text-align: left;
    .icon {
      width: 2rem;
      height: auto;
    }
  }
  .logo {
    width: 7em;
    height: auto;
  }
  .inputArea {
    width: 100%;
    padding-top: 20%;
    margin-bottom: 1rem;
        @media (min-width:480px){
      padding-top: 0%;
      margin-bottom: 0rem;
    }
  }
  .innerBtn {
    flex: 6.5rem 0 0;
    margin-left: 0.5rem;
    border: none;
    border-radius: 5px;
    height: 100%;
    color: var(--orange);
    font-size: 1.1rem;
    font-weight: 600;
    &:active {
      background-color: var(--pink);
    }
  }
  .registerBtn {
    width: 100%;
    color: var(--black);
    font-size: 0.8rem;
  }
  .dialog_ {
    &header {
      font-size: 0.9em;
    }
    &title {
      font-size: 1em;
      font-weight: 600;
    }
    &msg {
      font-size: 0.8em;
    }
  }
}
</style>
